/* eslint-disable no-nested-ternary */
import * as React from 'react'

// import { styled, alpha } from '@mui/material/styles'

import AccountCircle from '@mui/icons-material/AccountCircle'
// import AddIcon from '@mui/icons-material/Add'
import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
// import InputBase from '@mui/material/InputBase'
// import MailIcon from '@mui/icons-material/Mail'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import OfflineIcon from '@mui/icons-material/OfflineBolt'
import OnlineIcon from '@mui/icons-material/OnlinePrediction'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreIcon from '@mui/icons-material/MoreVert'
import NotificationsIcon from '@mui/icons-material/Notifications'
// import SearchIcon from '@mui/icons-material/Search'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import { QrReader } from './components/QR'

import { db } from './storage/database'
import { useOnlineStatus } from './components/useOnline'

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }))

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }))

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }))

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />)

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  // const [result, setResult] = React.useState(null)
  const [isScanning, setScanning] = React.useState(false)
  const [que, setQue] = React.useState(0)
  const [alert, setAlert] = React.useState(null)
  const online = useOnlineStatus()

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleNewScan = React.useCallback((evtResult) => {
    if (isScanning) {
      // setResult(evtResult.data)
      db.count({
        from: 'Receipts',
        where: {
          link: evtResult.data,
        },
      }).then((alreadyInDb) => {
        if (alreadyInDb) {
          setAlert('Receipt already saved!')
          return
        }
        setQue()
        setAlert('Receipt successfully scanned and added to que!')
        console.log('new scan added')
        db.insert({
          into: 'Receipts',
          values: [{ link: evtResult.data }],
        })
      })

      setScanning(false)
    }
  })

  const startScanning = () => {
    setScanning(true)
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          {online ? <OnlineIcon /> : <OfflineIcon />}
        </IconButton>
        <p>{online ? 'Online' : 'Offline'}</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={que} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  React.useEffect(() => {
    console.log('alert')
    if (alert !== false) {
      db.count({
        from: 'Receipts',
        where: {
          name: '',
        },
      }).then((unprocessed) => {
        setQue(Number(unprocessed))
      })
    }
    // .catch((err) => {
    //   console.log('error')
    // })
    // results will be a number
    // console.log(results)
  }, [alert])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
            T-BUD
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}
            <IconButton size="large" aria-label={online ? 'online' : 'offline'} color="inherit">
              {online ? <OnlineIcon /> : <OfflineIcon />}
            </IconButton>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={que} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center">
        {isScanning ? (
          <Box flexGrow={1} minHeight={400} minWidth={400}>
            <QrReader onResult={handleNewScan} />
          </Box>
        ) : (
          <Fab color="primary" aria-label="add" sx={{ width: 150, height: 150 }} onClick={startScanning}>
            <QrCodeScannerIcon sx={{ width: '70%', height: '70%' }} />
          </Fab>
        )}
      </Box>
      <Snackbar open={Boolean(alert)} autoHideDuration={6000} onClose={() => setAlert(false)}>
        <Alert
          onClose={() => setAlert(false)}
          severity={alert === 'Receipt already saved!' ? 'warning' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </Box>
  )
}
