import { DATA_TYPE, Connection } from 'jsstore'

export const db = new Connection(new Worker('./jsstore.worker.js'))

function getDbSchema() {
  const tblReceipts = {
    name: 'Receipts',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },
      link: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      pib: {
        dataType: DATA_TYPE.Number,
      },
      name: {
        dataType: DATA_TYPE.String,
        default: '',
      },
      address: {
        dataType: DATA_TYPE.String,
      },
      city: {
        dataType: DATA_TYPE.String,
      },
      total: {
        dataType: DATA_TYPE.Number,
      },
      items: {
        dataType: DATA_TYPE.Array,
      },
    },
  }

  const dbConf = {
    name: 't-bud',
    tables: [tblReceipts],
  }
  return dbConf
}

// eslint-disable-next-line no-extra-semi
;(async function initDb() {
  const isDbCreated = await db.initDb(getDbSchema())
  if (isDbCreated) {
    console.log('db created')
  } else {
    console.log('db opened')
  }
})()
