// After
import { createRoot } from 'react-dom/client'

import React from 'react'
// import ReactDOM from 'react-dom'

import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)

// ReactDOM.render(
//   [
//     <GlobalStyles
//       key="global"
//       styles={{
//         '#root': {
//           width: '100vw',
//           height: '100vh',
//           display: 'flex',
//           flexDirection: 'column',
//         },
//       }}
//     />,
//     <CssBaseline key="css-reset" />,
//     <App key="app" />,
//   ],
//   document.getElementById('root'),
// )

root.render([
  <GlobalStyles
    key="global"
    styles={{
      '#root': {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
    }}
  />,
  <CssBaseline key="css-reset" />,
  <App key="app" />,
])
