/* eslint-disable react/prop-types */
import * as React from 'react'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import IconButton from '@mui/material/IconButton'

import { styles } from './styles'
import { useQrReader } from './hooks'

export function QrReader({ onResult, facingMode }) {
  const videoRef = React.useRef(null)
  const [mounted, setMounted] = React.useState()
  const currentCamIdx = React.useRef(0)

  const { cameras, qrScanner } = useQrReader({
    onResult,
    facingMode,
    videoElement: videoRef.current,
  })

  const switchCamera = () => {
    currentCamIdx.current = cameras.length - 1 > currentCamIdx.current + 1 ? currentCamIdx.current + 1 : 0
    qrScanner.setCamera(cameras[currentCamIdx.current].id)
    console.log(`Setting the camera to ${cameras[currentCamIdx.current].label}`)
  }

  React.useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <section>
      <div style={{ ...styles.container }}>
        <video
          muted
          ref={videoRef}
          style={{
            ...styles.video,
            // transform: facingMode === 'user' && 'scaleX(-1)',
          }}
        />
        <IconButton onClick={switchCamera} sx={{ zIndex: 9999, position: 'absolute', bottom: 0 }}>
          <CameraswitchIcon />
          Switch Camera
        </IconButton>
      </div>
    </section>
  )
}
