import { useState, useRef, useEffect } from 'react'
import QrScanner from 'qr-scanner'

export const useQrReader = ({
  onResult,
  videoElement,
  preferredCamera = 'environment',
  highlightScanRegion = true,
  highlightCodeOutline = true,
}) => {
  const qrScanner = useRef(null)
  const [cameras, setCameras] = useState([])

  useEffect(() => {
    if (videoElement && !qrScanner.current) {
      console.log('init')
      qrScanner.current = new QrScanner(videoElement, onResult, {
        highlightScanRegion,
        highlightCodeOutline,
        preferredCamera,
        returnDetailedScanResult: true,
      })
      qrScanner.current.start()
      if (cameras.length === 0) {
        QrScanner.listCameras().then((cams) => setCameras(cams))
      }
    }

    return () => {
      if (qrScanner.current) {
        qrScanner.current.stop()
        qrScanner.current.destroy()
        qrScanner.current = null
      }
    }
  }, [videoElement])

  return { cameras, qrScanner: qrScanner.current }
}
